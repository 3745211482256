<template>
  <div class="mizun-box ">
    <titleHeader></titleHeader>
    <img src="../assets/images/mizun/mizun_01.jpg" alt="">
    <img src="../assets/images/mizun/mizun_02.jpg" alt="">
    <img src="../assets/images/mizun/mizun_03.jpg" alt="">
    <img src="../assets/images/mizun/mizun_04.jpg" alt="">
    
    <!-- <a href="tel:020-87593616"><img src="../assets/images/mizun/mizun_05.jpg" alt=""></a> -->
    <!-- <a href="tel:020-87595616"><img src="../assets/images/mizun/mizun_06.jpg" alt=""></a> -->
    <div class="flex" style="padding:20px 0 0;">
      <div class="flex1">
        <img src="../assets/images/mizun/kf.png" alt="">
      </div>
      <div class="flex1">
        <img src="../assets/images/mizun/mz.png" alt="">
      </div>
      <div class="flex1">
        <img src="../assets/images/mizun/gzh.png" alt="">
      </div>
    </div>
    <div class="flex" style="padding:40px 0;">
      <div class="flex1">
        <a href="tel:020-87593616"><img src="../assets/images/mizun/dh1.jpg" alt=""></a> 
      </div>
      <div class="flex1">
        <a href="tel:020-87595616"><img src="../assets/images/mizun/dh2.jpg" alt=""></a> 
      </div>
    </div>
    <!-- <img src="../assets/images/mizun/mizun_07.jpg" alt="">
    <img src="../assets/images/mizun/mizun_08.jpg" alt=""> -->

    
    
  </div>
</template>

<script>
export default {
  components: {
    
  },
  data() {
    return {
      id:'',
      
    }
    
  },
  
  created(){
    
    
  },
  mounted() {
   
    
  },
  methods: {
   
  },
};
</script>

<style scoped lang="scss">
.mizun-box{
  background: #fff;
  img{
    display: block;
  }
  .flex{
    display: flex;
    width: 100%;
    .flex1{
      flex:1;
    }
  }
  
}
</style>
